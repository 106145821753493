var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3rCcfhTz21iE3-zf6B5LF"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  // dsn: "https://1cf0d7a52e888450802984761ac45cb1@o253996.ingest.us.sentry.io/4506597139415040",
  dsn: "https://1cf0d7a52e888450802984761ac45cb1@proxy.henter.me/https://1cf0d7a52e888450802984761ac45cb1@o253996.ingest.sentry.io/4506597139415040",

  // dsn1:"https://fbbae1c681f15c4c3a00642a671c1ae8@o253996.ingest.sentry.io/4506597215502336",
  // dsn2:"https://fbbae1c681f15c4c3a00642a671c1ae8@proxy.henter.me/https://fbbae1c681f15c4c3a00642a671c1ae8@o253996.ingest.sentry.io/4506597215502336",

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true
    })
  ]
});
