export const menuData = [
  {
    name: "陪练",
    icon: "icon-a-3",
    key: "/coach",
    filter: () => true
  },
  {
    name: "课集",
    icon: "icon-a-1",
    key: "/book",
    filter: () => true
  },
  {
    name: "素材库",
    icon: "icon-tikuguanli",
    key: "/material",
    filter: (role_id: number) => ![4, 5]?.includes(role_id)
  },
  {
    name: "报告",
    icon: "icon-baobiao_report",
    key: "/report",
    filter: (role_id: number) => ![4]?.includes(role_id)
  },
  {
    name: "报告",
    icon: "icon-baobiao_report",
    key: "/record",
    filter: (role_id: number) => [4]?.includes(role_id)
  },
  {
    name: "设置",
    icon: "icon-a-31shezhi",
    key: "/setting",
    filter: (role_id: number) => ![4]?.includes(role_id)
  }
];
