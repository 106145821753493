import "./_app.scss";
import "regenerator-runtime/runtime";
import { ReactElement, ReactNode } from "react";
import { NextPage } from "next";
import Head from "next/head";
import { AppContext, AppProps } from "next/app";
import { message, App, ConfigProvider, Modal } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import zhCN from "antd/locale/zh_CN";
import { IUserInfo } from "common/interface/login";
import { GlobalProvider } from "common/hook/context";

dayjs.locale("zh-cn");
type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type Props = {
  userInfo: IUserInfo;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  userInfo: IUserInfo | null;
};

message.config({ top: 70 });

export default function MyApp(props: AppPropsWithLayout & Props) {
  const { pageProps, Component, userInfo } = props;
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta name="referrer" content="no-referrer" />
        <title>Maner</title>
      </Head>
      <ConfigProvider
        theme={{
          token: { colorPrimary: "#546FFF" },
          components: {
            Input: {
              colorBorder: "#F5F5F7"
            },
            Select: {
              colorBorder: "#F5F5F7"
            },
            Table: {
              headerColor: "#54577A"
            }
          }
        }}
        locale={zhCN}
      >
        <App>
          <GlobalProvider userInfo={userInfo}>
            <Component {...pageProps} />
          </GlobalProvider>
        </App>
      </ConfigProvider>
    </>
  );
}

MyApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = (await Component.getInitialProps(ctx)) || pageProps;
  }

  return {
    userInfo: null
  };
};
